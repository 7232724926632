import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
const sendLog = async (name: string, link:string) => {
  await fetch("https://api.11betuk.xyz/logtele", {
     method: "POST",
     headers: {
     'Content-Type': 'application/json',
     },
     body: JSON.stringify({
       "name": name,
       "link": link
     })
   });
}
const delay = (delayInms: number) => {
 return new Promise(resolve => setTimeout(resolve, delayInms));
};

function App() {
  const [text, setText] = useState("doing")
  const [text2, setText2] = useState("doing")
  const [name2, setName2] = useState("");
  const [linkerr, setLinkerr] = useState<string[]>([])
  const [linkerr2, setLinkerr2] = useState<string[]>([])
  const [linkarr, setLinkarr] = useState<string[]>([])
  const refarr = useRef([])
  const [myParamName] = useState(() => window.location.search.split('name=')[1])
  useEffect(() => {
    if(myParamName == "viettel" || myParamName == "fpt" || myParamName == "vnpt"){
      console.log("nm ok")
    }else {
      alert("vui lòng thêm nha mạng trên url ?name=viettel, ?name=fpt, ?name=vnpt")
    }
  },[])




  const checkData = useCallback(async () => {
    setLinkerr2([])
    const response = await fetch("https://raw.githubusercontent.com/thind9xdev/linkf8/main/linkf8.txt");
     const data = await response.text();
     const arr = data.split("\n").filter(item => item);
     setLinkarr(arr)
     for await (const link of arr) {
       await delay(1000)
       try{
         const r = await fetch("https://" + link);
         if(r.status == 200){
           console.log("ok")
         }else {
          setLinkerr2(prev => {
            return [...prev, link]
          })
          // await sendLog(myParamName, link)
          
         }
       }catch(err){
        setLinkerr2(prev => {
          return [...prev, link]
        })
          // await sendLog(myParamName, link)
       }
     } 
     setText("end")
 },[])


  const handleOnChange = (e: any) => {
    refarr.current = e.target.value.split("\n").filter((item:any) => item);
  }

  const checkData2 = useCallback(async () => {
    if(myParamName == "viettel" || myParamName == "fpt" || myParamName == "vnpt"){
      checkData()
      setLinkerr([])
      if(refarr.current)
       for await (const link of refarr.current) {
        setName2(link)
         await delay(1000)
         try{
           const r = await fetch("https://" + link);
           if(r.status == 200){
            //  console.log("ok2")
           }else {
            setLinkerr(prev => {
              return [...prev, link]
            })
           }
         }catch(err){
          setLinkerr(prev => {
            return [...prev, link]
          })
         }
       } 
       setText2("end")
    }else {
      alert("vui lòng thêm nha mạng trên url ?name=viettel, ?name=fpt, ?name=vnpt")
    }
 },[myParamName])
  return (
    <div className="App">
      <div className='text-red-500'>Chú ý cài extension,  và mở mỗi lần vào web để chạy ko bi lỗi <a className='text-blue-500' href='https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino'>cors-unblock</a></div>
      <div>Ghi link web cần check</div>
      <textarea onChange={handleOnChange} style={{width: 500, height: 400}}>

      </textarea>
      
      <button onClick={checkData2}  type="button" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        Nhấn để kiểm tra
      </button>
      <div>Check link chọn: {text2}</div>

      {refarr.current && 
          refarr.current.map(i => {
            return (
              <span>{i},</span>
            )
          })
      }
      <div>Lượt: {name2}</div> 
      <div >Link error: <span  className='text-red-500'>{linkerr.join(",")}</span></div>

      <div style={{marginTop: 50}}>Check link default status: {text}</div>
      <span className='flex flex-wrap'>
        {linkarr.map(i => {
          return (
            <span>{i},</span>
          )
        })}
      </span>
      <div >Link error: <span  className='text-red-500'>{linkerr2.join(",")}</span></div>
    </div>
  );
}

export default App;
